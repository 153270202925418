import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/KKQ0x1P3FnM">
    <SEO title="Jesus as the Foundation - Hebrews" />
  </Layout>
)

export default SermonPost
